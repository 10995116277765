<template>
  <div class="options cias pd-20">
    <a-page-header
      class="mb-10"
      style="padding: 0"
      sub-title=""
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>{{ airline.id }} - Editar Cia</h1>
      </div>
      <template slot="extra"> </template>
    </a-page-header>

    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <h2 class="title mb-20">DADOS DA CIA AÉREA</h2>

            <a-col :span="8" :offset="0">
              <a-form-item class="travel-input-outer">
                <label :class="form.getFieldValue(`name`) ? 'filled' : ''">
                  Nome simplificado
                </label>

                <a-input
                  class="travel-input"
                  placeholder="Nome simplificado"
                  v-decorator="[
                    `name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="16">
              <a-form-item class="travel-input-outer">
                <label
                  :class="form.getFieldValue(`company_name`) ? 'filled' : ''"
                >
                  Razão social
                </label>

                <a-input
                  class="travel-input"
                  placeholder="Razão social"
                  v-decorator="[
                    `company_name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item class="travel-input-outer">
                <label
                  :class="form.getFieldValue(`trading_name`) ? 'filled' : ''"
                >
                  Nome Fantasia
                </label>

                <a-input
                  class="travel-input"
                  placeholder="Nome Fantasia"
                  v-decorator="[
                    `trading_name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item class="travel-input-outer">
                <label :class="form.getFieldValue(`cnpj`) ? 'filled' : ''">
                  CNPJ
                </label>

                <a-input
                  class="travel-input"
                  placeholder="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  v-decorator="[`cnpj`]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item class="travel-input-outer">
                <label :class="form.getFieldValue(`url`) ? 'filled' : ''">
                  URL
                </label>

                <a-input
                  class="travel-input"
                  placeholder="URL"
                  v-decorator="[`url`]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              {{ $refs.vueFileAgent }}
              <FileUpload
                :folder="`public/airlines/${airline.id}`"
                :multiple="false"
                :deletable="true"
                :sortable="false"
                :meta="false"
                :theme="'default'"
                :maxSize="'10MB'"
                :accept="'image/*'"
                :maxFiles="1"
                :text="'Logo da cia'"
                :userID="true"
                :userFolder="false"
                :url="form.getFieldValue(`logo`)"
                :id="airline.id"
                @fileURL="fileURL"
              />

              <a-form-item style="display: none">
                <a-input
                  placeholder="LOGO"
                  v-decorator="[`logo`]"
                  style="text-transform: none"
                >
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="box box-contractor" :span="16">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>PROGRAMA DE MILHAS</h3>
              </a-col>
              <a-col>
                <a-button
                  type="link"
                  size="small"
                  ghost
                  @click="showCreateMilesProgram = true"
                >
                  <a-icon type="plus" /> ADICIONAR PROGRAMA
                </a-button>

                <a-modal
                  v-model="showCreateMilesProgram"
                  title="NOVO PROGRAMA"
                  :footer="false"
                  width="340px"
                  @cancel="cancelMilesProgramCreation"
                >
                  <a-input
                    v-model="milesProgram.name"
                    placeholder="Nome do programa"
                  />

                  <a-popconfirm
                    ok-text="Sim"
                    cancel-text="Cancelar"
                    placement="bottom"
                    @confirm="createNewProgram"
                  >
                    <template slot="title">
                      <div style="font-size: 13px">
                        Após a criação, o programa de milhas
                        <br />
                        não poderá ser apagado. <br />
                        Deseja continuar?
                      </div>
                    </template>
                    <a-button
                      class="mt-20"
                      type="primary"
                      style="width: 100%; font-weight: 500"
                      :loading="milesProgram.loading"
                    >
                      CRIAR PROGRAMA DE MILHAS
                    </a-button>
                  </a-popconfirm>
                </a-modal>
              </a-col>
            </a-row>

            <a-row
              v-for="(program, index) in airline.miles_programs"
              :key="index"
              :gutter="20"
              style="padding: 20px 10px"
            >
              <a-row
                type="flex"
                justify="space-between"
                style="padding: 0px 10px"
              >
                <a-col :span="8">
                  <a-form-item class="travel-input-outer">
                    <label
                      :class="
                        form.getFieldValue(`miles_program_name_${index}`)
                          ? 'filled'
                          : ''
                      "
                    >
                      Nome do Programa de milhas
                    </label>

                    <a-input
                      class="travel-input readonly"
                      placeholder="Nome do Programa de milhas"
                      v-decorator="[
                        `miles_program_name_${index}`,
                        {
                          initialValue: program.id + ' - ' + program.name,
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>

              <div style="clear: both"></div>

              <a-col :span="24">
                <h3 class="title mb-30">
                  <a-row>
                    <a-col :span="8"> PREÇO POR FAIXA DE MILHAS </a-col>
                    <a-col class="a-right" :span="8" :offset="8">
                      <a-button
                        size="small"
                        @click="addMilesPriceRange(program.id, index)"
                        type="link"
                      >
                        Adicionar nova faixa
                      </a-button>
                    </a-col>
                  </a-row>
                </h3>
              </a-col>

              <div style="clear: both"></div>

              <a-row
                v-for="(range, i) in formatMilesRange(program.miles_range)"
                :key="i"
                :gutter="16"
                style="padding: 0 10px"
              >
                <a-col
                  :span="1"
                  style="
                    font-size: 12px;
                    font-weight: 600;
                    color: #7c6eac;
                    position: relative;
                    top: 14px;
                    left: 8px;
                  "
                >
                  #{{ i + 1 }}
                </a-col>
                <a-col :span="6">
                  <a-form-item class="travel-input-outer">
                    <label
                      :class="
                        form.getFieldValue(
                          `miles_program_${index}_miles_range_${i}_start_miles`
                        ) || range.miles_start
                          ? 'filled'
                          : ''
                      "
                    >
                      Quantidade inicial de milhas
                    </label>

                    <a-input
                      class="travel-input"
                      type="number"
                      placeholder="Quantidade inicial de milhas"
                      @change="updateMilesRange(program.id, index, i)"
                      v-decorator="[
                        `miles_program_${index}_miles_range_${i}_start_miles`,
                        {
                          initialValue: range.miles_start,
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="6">
                  <a-form-item class="travel-input-outer">
                    <label
                      :class="
                        form.getFieldValue(
                          `miles_program_${index}_miles_range_${i}_end_miles`
                        ) || range.miles_end
                          ? 'filled'
                          : ''
                      "
                    >
                      Quantidade final de milhas
                    </label>

                    <a-input
                      type="number"
                      class="travel-input"
                      placeholder="Quantidade final de milhas"
                      @change="updateMilesRange(program.id, index, i)"
                      v-decorator="[
                        `miles_program_${index}_miles_range_${i}_end_miles`,
                        {
                          initialValue: range.miles_end,
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="6">
                  <a-form-item class="travel-input-outer">
                    <label
                      :class="
                        form.getFieldValue(
                          `miles_program_${index}_miles_range_${i}_price`
                        ) || range.price
                          ? 'filled'
                          : ''
                      "
                    >
                      Preço para cada 1000 milhas aéreas
                    </label>

                    <a-input
                      class="travel-input"
                      v-currency="{
                        precision: 3,
                      }"
                      placeholder="Preço para cada 1000 milhas aéreas"
                      @change="updateMilesRange(program.id, index, i)"
                      v-decorator="[
                        `miles_program_${index}_miles_range_${i}_price`,
                        {
                          initialValue: range.price,
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="1">
                  <a-button
                    v-if="formatMilesRange(program.miles_range).length - 1 == i"
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    @click="removePriceRange(program.id, i)"
                  >
                  </a-button>
                </a-col>
              </a-row>
            </a-row>
          </a-row>
        </a-col>

        <a-col class="" :span="24">
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            :loading="loading"
            style="float: right"
          >
            SALVAR ALTERAÇÕES
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import FileUpload from "@/components/atoms/FileUpload.vue";
export default {
  name: "EditAirline",
  components: { FileUpload },
  data() {
    return {
      form: this.$form.createForm(this),
      airline: {},
      milesProgram: {
        name: undefined,
        miles_range: "[]",
        loading: false,
      },
      showCreateMilesProgram: false,
      loading: false,
    };
  },
  beforeMount() {
    this.getAirline();
  },
  methods: {
    formatMilesRange(mileRange) {
      return typeof mileRange == "string" ? JSON.parse(mileRange) : mileRange;
    },
    createNewProgram() {
      if (this.milesProgram.name) {
        this.milesProgram.loading = true;
        this.milesProgram.miles_range = "[]";

        this.$http
          .post("/miles-program/create", this.milesProgram)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.milesProgram.loading = false;
            this.showCreateMilesProgram = false;
            this.milesProgram = {
              name: undefined,
              miles_range: "[]",
              loading: false,
            };
            this.getAirline();
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.milesProgram.loading = false;
          });
      } else {
        this.$message.warning("Escreva o nome do programa de milhas.");
      }
    },
    cancelMilesProgramCreation() {
      this.milesProgram = {
        name: undefined,
        miles_range: false,
      };
    },
    getAirline() {
      this.$http
        .post("/airline/details", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.airline = data;

          document.title = `${data.id} - ${data.name} - HAYA`;

          this.form.setFieldsValue({
            [`name`]: data.name,
            [`company_name`]: data.company_name,
            [`trading_name`]: data.trading_name,
            [`cnpj`]: data.cnpj,
            [`url`]: data.url,
            [`logo`]: data.logo,
          });

          this.milesProgram.airline_id = data.id;

          this.loadingCreateAirline = false;
          this.$emit("listAirlines", true);
        })
        .catch(({ response }) => {
          response;
          this.loadingCreateAirline = false;
        });
    },
    fileURL(url) {
      console.log("chegou url", url);
      this.form.setFieldsValue({
        [`logo`]: url,
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.airline.id;
        values.user_id = this.airline.user.id;
        values.miles_programs = this.airline.miles_programs;
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        console.log(err, values);

        if (!err) {
          this.loading = true;
          this.$http
            .post("/airline/update", values)
            .then(({ data }) => {
              data;
              this.$message.success("Atualizado com sucesso!");
              this.loading = false;
            })
            .catch(({ res }) => {
              res;
              this.$message.error("Não foi possível atualizar.");
              this.loading = false;
            });
        } else {
          this.$message.warning("Alguns campos ainda não foram preenchidos.");
        }
      });
    },
    addMilesPriceRange(id, index) {
      index;
      for (var i in this.airline.miles_programs) {
        if (this.airline.miles_programs[i].id == id) {
          let range = [];

          if (typeof this.airline.miles_programs[i].miles_range == "string") {
            range = JSON.parse(this.airline.miles_programs[i].miles_range);
          } else {
            range = this.airline.miles_programs[i].miles_range;
          }

          range.push({
            miles_start: "",
            miles_end: "",
            price: "",
          });

          this.airline.miles_programs[i].miles_range = JSON.stringify(range);

          break;
        }
      }
    },
    updateMilesRange(milesProgramId, index, rangeId) {
      index;
      rangeId;
      this.airline.miles_programs.forEach((milesProgram, i) => {
        if (milesProgram.id == milesProgramId) {
          let newrange = [];
          JSON.parse(milesProgram.miles_range).forEach((range, j) => {
            setTimeout(() => {
              range.miles_start = this.form.getFieldValue(
                `miles_program_${i}_miles_range_${j}_start_miles`
              );
              range.miles_end = this.form.getFieldValue(
                `miles_program_${i}_miles_range_${j}_end_miles`
              );
              range.price = this.form.getFieldValue(
                `miles_program_${i}_miles_range_${j}_price`
              );

              newrange.push(range);
            }, 100);
          });
          setTimeout(() => {
            milesProgram.miles_range = JSON.stringify(newrange);
          }, 200);
        }
      });
    },
    removePriceRange(milesProgramId, rangeId) {
      for (var i in this.airline.miles_programs) {
        if (this.airline.miles_programs[i].id == milesProgramId) {
          if (typeof this.airline.miles_programs[i].miles_range == "string") {
            let a = JSON.parse(this.airline.miles_programs[i].miles_range);

            a.splice(rangeId, 1);

            this.airline.miles_programs[i].miles_range = JSON.stringify(a);

            console.log(a);
          } else {
            let a = this.airline.miles_programs[i].miles_range;
            a.splice(rangeId, 1);

            this.airline.miles_programs[i].miles_range = JSON.stringify(a);
          }

          break;
        }
      }
    },
    addMilesProgram(id) {
      console.log("addMilesProgram", id);

      for (var i in this.airlines) {
        if (this.airlines[i].id == id) {
          console.log(
            "addMilesProgram",
            id,
            this.airlines[i].name,
            this.airlines[i]
          );
          this.airlines[i].miles_programs.push({
            name: "",
            miles_range: [],
          });

          break;
        }
      }
    },
    removeMilesProgram(id, index) {
      for (var i in this.airlines) {
        if (this.airlines[i].id == id) {
          this.airlines[i].miles_programs.splice(index, 1);
          break;
        }
      }
    },
  },
};
</script>
